import {AiChat} from '@nlux/react';
import {useChatAdapter} from '@nlux/langchain-react';
import '@nlux/themes/nova.css';
import './custom-nova-theme.css';

const App = () => {
  // LangServe adapter that connects to a demo LangChain Runnable API
  const adapter = useChatAdapter({
    url: 'https://pynlux.api.nlux.ai/pirate-speak'
  });

  return (
    <AiChat
      adapter={adapter}
      className="custom-ai-chat-comp"
      personaOptions={{
        bot: {
          name: 'Chefkoch',
          picture: 'cook-avatar.png',
          tagline: 'Wie kann ich dir beim Kochen behilflich sein?',
        },
        user: {
          name: 'Basti',
          picture: 'basti-avatar.png'
        }
      }}
      layoutOptions={{
        height: '100%',
        maxWidth: '600px'
      }}
      promptBoxOptions={{
        autoFocus: true,
        placeholder: "Chatnachricht eingeben..."
    }}
    />
  );
};

export default App;